<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Account</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Account #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.accountNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Account Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.accountName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>D/C</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-radio-group v-model="item.nature" row>
                  <v-radio label="Debit" value="Debit"></v-radio>
                  <v-radio label="Credit" value="Credit"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Reff</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="item.reference"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="item.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Original Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="item.totalAmount"
                  @input="onInputTotal"
                  persistent-hint
                  :hint="formatPrice(item.totalAmount)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  v-model="item.rate"
                  @input="onInputRate"
                  persistent-hint
                  :hint="formatPrice(item.rate)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Amount In RP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="item.totalAmountIDR"
                  persistent-hint
                  :hint="formatPrice(item.totalAmountIDR)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Project</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="item.project"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Dept</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="item.department"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>PIB</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-checkbox v-model="item.pib"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="item.pib">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>NTPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="item.ntpn"
                  item-text="ntpn"
                  item-value="ntpn"
                  :items="listPib"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="success" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-account-gl",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    reff: String,
    remark: String,
    listPib: Array,
  },
  methods: {
    save() {
      this.$emit("autoComplete", { reff: this.item.reference, remark: this.item.remark });
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
    onInputRate() {
      this.item.totalAmountIDR = this.item.rate * this.item.totalAmount;
    },
    onInputTotal() {
      this.item.totalAmountIDR = this.item.rate * this.item.totalAmount;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
