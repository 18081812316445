<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    :allowed-dates="getAllowedDates()"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucher"
                  :items="listVoucher"
                  :rules="[v => !!v || 'Voucher is required']"
                  @change="onChangeVoucher"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  :items="listPO"
                  background-color="white"
                  v-model="form.poNumber"
                  :rules="[v => !!v || 'Type is required']"
                  @change="onChangeType"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>WO Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.woNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3">
                <v-subheader>Journal Not Balance</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.notBalance"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="8" sm="8" md="3" lg="3" class="py-0">
                <v-checkbox v-model="form.downPayment" label="Down Payment"></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="3" lg="3" class="py-0">
                <v-checkbox v-model="form.cheque" label="Cheque/Giro#"></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="form.isCheque">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  :items="listCheque"
                  v-model="form.chequeNumber"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12" lg="12">
            <form-tax-import
              :form="form"
              :listInvoice="listInvoice"
              v-if="form.poNumber === 'Import'"
              @add="addTaxImport"
            ></form-tax-import>
            <form-tax-other-document
              :form="form.otherDocument"
              :vendors="vendors"
              v-if="form.poNumber === 'Other Document'"
            ></form-tax-other-document>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="accounts"
                        item-text="accountName"
                        return-object
                        v-model="account"
                        :filter="filterObject"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="data.item.accountNumber"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" align-self="start" class="text-right">
                      <p class="headline font-italic">Account <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalAmountIDR="{ item }">
                          {{ formatPrice(item.totalAmountIDR) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="4" md="2" lg="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Debit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalDebit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="2" lg="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Credit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalCredit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-account-gl
      :item="item"
      :index="index"
      :dialog="dialog"
      @save="addItem"
      @close="close"
      @deleteItem="deleteItem"
      @autoComplete="autoComplete"
      :listPib="form.taxs"
    ></dialog-account-gl>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogAccountGL from "@/components/DialogAccountGL";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import FormTaxImport from "./FormTaxImport.vue";
import FormTaxOtherDocument from "./FormTaxOtherDocument.vue";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-gl-transaction",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-account-gl": DialogAccountGL,
    DialogReviewJournal,
    FormTaxImport,
    FormTaxOtherDocument,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      date: moment().format("yyyy-MM-DD"),
      downPayment: false,
      cheque: false,
      chequeNumber: "",
      voucher: "",
      poNumber: null,
      woNumber: "",
      replacementCostId: null,
      giroId: null,
      totalDebit: 0,
      totalCredit: 0,
      glTransactionCOAList: [],
      notBalance: false,
      taxs: [],
      otherDocument: {},
    },
    defaultTax: {
      taxDate: moment().format("yyyy-MM-DD"),
      pibDate: moment().format("yyyy-MM-DD"),
      taxNumber: "",
      stan: "",
      awb: "",
      ntpn: "",
      invoiceType: "",
      invoiceId: 0,
      menuTaxDate: false,
      menuPibDate: false,
      invoice: {},
    },
    defaultOtherDocument: {
      taxDate: moment().format("yyyy-MM-DD"),
      taxNumber: "",
      vendorId: 0,
      vendorName: "",
      invoiceNumber: "",
    },
    valid: true,
    menuDate: false,
    form: {},
    items: [],
    dialog: false,
    dialogReview: false,
    item: {},
    index: -1,
    account: null,
    accounts: [],
    tempAccounts: [],
    listInvoice: [],
    listVoucher: ["Petty Cash", "Journal", "Received", "Payment"],
    listPO: ["PO Customer", "BPO", "Internal", "Forecast", "Domestic", "Import", "Other Document"],
    reff: "",
    remark: "",
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Original Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount in RP",
        value: "totalAmountIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "NTPN",
        value: "ntpn",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    ...mapState("glTransaction", ["lastFormNumber"]),
    ...mapState("replacementCost", ["listReplacementCost"]),
    ...mapState("giro", ["listGiro"]),
    ...mapState("external", ["vendors"]),
    totalDebitCalculation() {
      let total = 0;
      this.items.forEach(x => {
        if (x.nature === "Debit") {
          total += x.totalAmountIDR;
        }
      });
      return total.toFixed(2);
    },
    totalCreditCalculation() {
      let total = 0;
      this.items.forEach(x => {
        if (x.nature === "Credit") {
          total += x.totalAmountIDR;
        }
      });
      return total.toFixed(2);
    },
  },
  watch: {
    totalDebitCalculation(val) {
      this.form.totalDebit = val;
    },
    totalCreditCalculation(val) {
      this.form.totalCredit = val;
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [];
        this.items.forEach(x => {
          if (x.nature === "Debit") {
            this.journals.unshift({
              accountNumber: x.accountNumber,
              accountName: x.accountName,
              debit: x.totalAmount,
              credit: 0,
            });
          } else {
            this.journals.push({
              accountNumber: x.accountNumber,
              accountName: x.accountName,
              debit: 0,
              credit: x.totalAmount,
            });
          }
        });
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.glTransactionCOAList = this.items;

        let totalCredit = this.totalCreditCalculation;
        let totalDebit = this.totalDebitCalculation;

        if (this.form.notBalance) {
          this.$store.commit("SET_LOADER", true);
          await this.$store
            .dispatch("glTransaction/create", this.form)
            .then(() => this.$store.commit("SET_LOADER", false))
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          if (totalDebit - totalCredit === 0) {
            this.$store.commit("SET_LOADER", true);
            await this.$store
              .dispatch("glTransaction/create", this.form)
              .then(() => this.$store.commit("SET_LOADER", false))
              .catch(() => {
                this.$store.dispatch("toast", {
                  type: "error",
                  message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
                });
                this.$store.commit("SET_LOADER", false);
              });
            this.clear();
          } else {
            let different = totalDebit - totalCredit;
            if (different > 0) {
              this.$store.dispatch("toast", {
                type: "error",
                message: `Journal not balance with -${Math.abs(different)} on Debit`,
              });
            } else {
              this.$store.dispatch("toast", {
                type: "error",
                message: `Journal not balance with -${Math.abs(different)} on Credit`,
              });
            }
          }
        }
      } else {
        this.$store.dispatch("toast", { type: "error", message: "Account is required" });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.account = null;
        this.accounts = this.tempAccounts.map(x => ({ ...x }));
        this.dialog = false;
      }
    },
    close() {
      this.account = null;
      this.accounts = this.tempAccounts.map(x => ({ ...x }));
      this.dialog = false;
    },
    addItem(item) {
      if (this.index > -1) {
        this.items[this.index] = item;
      } else {
        this.items.push({ ...item });
      }
      this.account = null;
      this.accounts = this.tempAccounts.map(x => ({ ...x }));
    },
    autoComplete(item) {
      this.reff = item.reff;
      this.remark = item.remark;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    async addAccount(item) {
      if (item.currency !== "IDR") {
        await this.$store
          .dispatch("currency/getRate", { code: item.currency, date: this.form.date })
          .then(response => (item.rate = response.data));
      }
      item.reference = this.reff;
      item.remark = this.remark;
      this.index = this.items.findIndex(x => x === item);
      this.item = item;
      this.dialog = true;
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
        this.tempAccounts = response.data.map(x => ({ ...x }));
      }
    },
    onChangeReplacementCost() {},
    onChangeVoucher() {},
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    addTaxImport() {
      this.form.taxs.push({ ...this.defaultTax });
    },
    onChangeType(val) {
      if (val === "Import") {
        this.$store.commit("SET_LOADER", true);
        this.$store
          .dispatch("purchaseInvoice/getInvoices")
          .then(response => {
            this.listInvoice = response.data;
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else if (val === "Other Document") {
        this.$store.commit("SET_LOADER", true);
        this.form.otherDocument = Object.assign({}, this.defaultOtherDocument);
        this.$store
          .dispatch("external/getVendors")
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.form.tax = {};
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAccounts(),
        await this.$store.dispatch("glTransaction/getLastFormNumber"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
