<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Tax Date<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-menu
              v-model="menuTaxDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxDate"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.taxDate" @input="menuTaxDate = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Tax Number<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-text-field
              outlined
              flat
              dense
              background-color="white"
              v-model="form.taxNumber"
              :rules="[v => !!v || 'Tax Number is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Vendor<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-autocomplete
              flat
              dense
              outlined
              background-color="white"
              item-text="supplier_name"
              append-icon="mdi-magnify"
              return-object
              :items="vendors"
              v-model="vendor"
              @change="onChangeVendor"
              :rules="[v => !!v || 'Vendor is required']"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Invoice Number<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-text-field
              outlined
              flat
              dense
              background-color="white"
              v-model="form.invoiceNumber"
              :rules="[v => !!v || 'Invoice Number is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "form-tax-import",
  props: {
    form: Object,
    vendors: Array,
  },
  data: () => ({
    menuTaxDate: false,
    vendor: {},
  }),

  methods: {
    onChangeVendor(val) {
      this.form.vendorId = val.id;
      this.form.vendorName = val.supplier_name;
    },
  },
};
</script>

<style></style>
